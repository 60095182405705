
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Input, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const Officials = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [officials, setOfficials] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5); // Number of items per page
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [currentOfficial, setCurrentOfficial] = useState(null);

  useEffect(() => {
    fetchOfficials();
  }, []);

  const fetchOfficials = async () => {
    try {
      const response = await axios.get('https://tradeoffice-api.onrender.com/api/officials');
      setOfficials(response.data);
    } catch (error) {
      console.error('Error fetching officials:', error);
    }
  };

  const handleSubmit = async (values) => {
    // Basic validation
    if (!values.title || !values.name || !values.image) {
      message.error('Please fill in all fields and upload an image');
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('name', values.name);
    formData.append('image', values.image[0].originFileObj);

    try {
      // Send POST request to backend to add official
      await axios.post('https://tradeoffice-api.onrender.com/api/officials', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Official added successfully');
      // Refresh officials list after adding
      fetchOfficials();
      // Reset form fields after submission
      form.resetFields();
    } catch (error) {
      console.error('Error adding official:', error);
      message.error('Failed to add official');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this official?')) {
      try {
        await axios.delete(`https://tradeoffice-api.onrender.com/api/officials/${id}`);
        message.success('Official deleted successfully');
        fetchOfficials(); // Refresh officials list after deletion
      } catch (error) {
        console.error('Error deleting official:', error);
        message.error('Failed to delete official');
      }
    }
  };

  const handleView = (record) => {
    Modal.info({
      title: 'View Official',
      width: 800,
      content: (
        <div>
          <p><strong>Title:</strong> {record.title}</p>
          <p><strong>Name:</strong> {record.name}</p>
          <p><strong>Image:</strong> <img src={`https://tradeoffice-api.onrender.com/${record.imageURL}`} alt={record.title} style={{ maxWidth: '100%', height: 'auto' }} /></p>
        </div>
      ),
      onOk() {},
    });
  };

  const handleUpdate = (record) => {
    setCurrentOfficial(record);
    updateForm.setFieldsValue({
      title: record.title,
      name: record.name,
      image: null, // Image upload field is handled separately
    });
    setIsUpdateModalVisible(true);
  };

  const handleUpdateSubmit = async (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('name', values.name);
    if (values.image && values.image.length > 0) {
      formData.append('image', values.image[0].originFileObj);
    }

    try {
      // Send PUT request to backend to update official
      await axios.put(`https://tradeoffice-api.onrender.com/api/officials/${currentOfficial._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Official updated successfully');
      // Refresh officials list after updating
      fetchOfficials();
      // Close the update modal
      setIsUpdateModalVisible(false);
    } catch (error) {
      console.error('Error updating official:', error);
      message.error('Failed to update official');
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
      ellipsis: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      ellipsis: true,
    },
    {
      title: 'Image',
      dataIndex: 'imageURL',
      key: 'imageURL',
      width: '25%',
      render: (imageURL, record) => (
        <img src={`https://tradeoffice-api.onrender.com/${imageURL}`} alt={record.title} style={{ maxWidth: '100px', height: 'auto' }} />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '25%',
      render: (text, record) => (
        <div className="space-x-4">
          <Button type="primary" onClick={() => handleView(record)}>View</Button>
          <Button type="default" className='bg-green-700 p-2 text-white' onClick={() => handleUpdate(record)}>Update</Button>
          <Button type="default" className='bg-red-700 p-2 text-white' onClick={() => handleDelete(record._id)}>Delete</Button>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full mx-auto p-8 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4 text-blue-800">Officials Management</h2>
      <Form form={form} onFinish={handleSubmit} layout="vertical" className="space-y-4">
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Please enter the title' }]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter the name' }]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
        <Form.Item
          name="image"
          label="Image"
          valuePropName="fileList"
          getValueFromEvent={(e) => e.fileList}
          rules={[{ required: true, message: 'Please upload an image' }]}
        >
          <Upload
            listType="picture"
            maxCount={1}
            beforeUpload={() => false} // Disable default upload behavior
          >
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Add Official</Button>
        </Form.Item>
      </Form>

      <Table
        columns={columns}
        dataSource={officials}
        pagination={{ pageSize: pageSize, current: currentPage }}
        rowKey="_id"
        onChange={(pagination) => setCurrentPage(pagination.current)}
      />

      <Modal
        title="Update Official"
        visible={isUpdateModalVisible}
        onCancel={() => setIsUpdateModalVisible(false)}
        footer={null}
      >
        <Form form={updateForm} onFinish={handleUpdateSubmit} layout="vertical" className="space-y-4">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please enter the title' }]}
          >
            <Input placeholder="Enter title" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter the name' }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
          <Form.Item
            name="image"
            label="Image"
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
          >
            <Upload
              listType="picture"
              maxCount={1}
              beforeUpload={() => false} // Disable default upload behavior
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Update Official</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Officials;

