import React from "react";
import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import IMG01 from "../assets/images/Carousel/ah.jpg";
import IMG02 from "../assets/images/Carousel/Adaneche.png";
import IMG03 from "../assets/images/Carousel/Bole Head.jpg";
import IMG003 from "../assets/images/Carousel/Shemsiya Negash.jpg";
import LOGO from "../assets/images/Trade Logo.png";
import { useTranslation } from "react-i18next";

const contentStyle = "relative h-[75vh] w-full overflow-hidden bg-gray-800 text-center";
const imageStyle = "w-full h-full object-fit";
const textStyle = "absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 text-white py-4 px-4 text-4xl font-bold rounded text-sm md:text-2xl";

const App = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="flex justify-center items-center bg-slate-600">
      <div className="w-1/6 text-center text-white px-4">
        <p className="text-white font-bold text-xl">
          Bole Sub-City Administration Trade Office
        </p>
        <p className="text-white font-bold text-xl">
          Welcome!
        </p>
      </div>
      <div className="w-4/6">
        <Carousel
          autoplay
          dotPosition="bottom"
          effect="scrollx"
          arrows
          prevArrow={<LeftOutlined className="custom-arrow custom-prev-arrow" />}
          nextArrow={<RightOutlined className="custom-arrow custom-next-arrow" />}
          autoplaySpeed={3000} // Set the timing to 3000 milliseconds (3 seconds)
          className="h-[75vh]"
        >
          <div className={contentStyle}>
            <img className={imageStyle} src={IMG01} alt="Image 1" />
            <div className={textStyle}>
              <p>Prime Minister Doctor Abiy Ahmed</p>
              <p>Prime Minister of Ethiopia</p>
            </div>
          </div>

          <div className={contentStyle}>
            <img className={imageStyle} src={IMG02} alt="Image 2" />
            <div className={textStyle}>
              <p>Ms. Adanech Abebe</p>
              <p>Addis Ababa City Mayor</p>
            </div>
          </div>

          <div className={contentStyle}>
            <img className={imageStyle} src={IMG03} alt="Image 3" />
            <div className={textStyle}>
              <p>Ms. Alemtsehay Shiferaw</p>
              <p>Chief Executive Officer of Bole Sub City</p>
            </div>
          </div>
          <div className={contentStyle}>
            <img className={imageStyle} src={IMG003} alt="Image 3" />
            <div className={textStyle}>
              <p>Ms. Shemsiya Negash</p>
              <p>Head of Bole Sub City Trade Office</p>
            </div>
          </div>
          <div className={contentStyle}>
            <img
              className="w-full object-fill h-[80vh]"
              src={LOGO}
              alt="Image 7"
            />
            <div className={textStyle}>
              <p>Our Office Logo</p>
            </div>
          </div>
        </Carousel>
      </div>
      <div className="w-1/6 text-center text-white px-4">
        <p className="font-bold text-xl text-white">የቦሌ ክፍለ ከተማ አስተዳደር ንግድ ፅህፈት ቤት</p>
        <p className="font-bold text-xl text-white">እንኳን ደህና መጡ።</p>
      </div>
    </div>
  );
}

export default App;
