import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Prosperity from "../assets/images/Partners/prosperity.jpg";
import MayorOffice from "../assets/images/Partners/MayorOffice.png";
import BoleSubcity from "../assets/images/Partners/BoleSubcity.png";
import TradeCheck from "../assets/images/Partners/MOT.png";
import Nigd from "../assets/images/TRADELOGO.png";

import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const contentStyle = {
  height: "200px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "#1e293b",
  color: "#fff",
  borderRight: "3px solid #fff",
};

const imageStyle = {
  width: "200px",
  height: "200px",
  objectFit: "cover",
  margin: "auto",
};

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const { t } = useTranslation();

  return (
    <div className="pt-4 border-3">
      <div data-aos="zoom-in" data-aos-duration="2000">
        <h2
          style={{ textAlign: "center", marginBottom: "20px" }}
          className="text-blue-800 font-bold text-4xl"
        >
          {t("our partners")}
        </h2>
      </div>
      <Carousel
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        itemClass="carousel-item-padding-40-px"
      >
        <div data-aos="fade-up" style={contentStyle}>
          <a
            className="hover:scale-110"
            href="https://www.prosperity.org.et/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Nigd}
              style={imageStyle}
              alt="Prosperity"
              className="rounded-lg p-4 hover:scale-105"
            />
          </a>
          <a
            className="hover:scale-110"
            href="https://www.facebook.com/profile.php?id=100069351701097&locale"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3 className="text-white text-2xl font-bold hover:text-blue-500">
              {t("Trade Bureau")}
            </h3>
          </a>
        </div>

        <div data-aos="fade-up" style={contentStyle}>
          <a
            className="hover:scale-110"
            href="https://www.prosperity.org.et/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Prosperity}
              style={imageStyle}
              alt="Prosperity"
              className="rounded-lg p-4 hover:scale-105"
            />
          </a>
          <a
            className="hover:scale-110"
            href="https://www.prosperity.org.et//"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3 className="text-white text-2xl font-bold hover:text-blue-500">
              {t("Prosperity Party")}
            </h3>
          </a>
        </div>

        <div data-aos="fade-up" style={contentStyle}>
          <a
            href="https://www.prosperity.org.et/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={MayorOffice}
              style={imageStyle}
              alt="Mayor Office"
              className="rounded-lg p-4 hover:scale-105"
            />
          </a>
          <a
            className="hover:scale-110"
            href="https://www.addismayor.gov.et/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3 className="text-white text-2xl font-bold hover:text-blue-500">
              {t("Mayor Office")}
            </h3>
          </a>
        </div>

        <div data-aos="fade-up" style={contentStyle}>
          <a
            href="https://www.opp.et/about-us"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={BoleSubcity}
              style={imageStyle}
              alt="Bole Sub City"
              className="rounded-lg p-4 hover:scale-105"
            />
          </a>
          <a
            className="hover:scale-110"
            href="https://www.bolesubcityadministration.gov.et/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3 className="text-white text-2xl font-bold hover:text-blue-500">
              {t("Bole Sub City")}
            </h3>
          </a>
        </div>

        <div data-aos="fade-up" style={contentStyle}>
          <a
            href="https://insa.gov.et/web/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={TradeCheck}
              style={imageStyle}
              alt="E-Trade"
              className="rounded-lg p-4 hover:scale-105"
            />
          </a>
          <a
            className="hover:scale-110"
            href="https://etrade.gov.et/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3 className="text-white text-2xl font-bold hover:text-blue-500">
              {t("E-Trade")}
            </h3>
          </a>
        </div>
      </Carousel>
    </div>
  );
}

export default App;
