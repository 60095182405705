import React from 'react';
import { useTranslation } from "react-i18next";

function Location() {
  const { t, i18n } = useTranslation();
  return (
    <div className="w-full">
        <div className='text-4xl lg:text-4xl font-extrabold text-center text-blue-800'>
            <h1>{t('Location')}</h1>
        </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.2179247342115!2d38.761553275338265!3d9.043875791018099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8f6f408b25e9%3A0xd4e77b0cb35d6228!2sSupreme%20Court%20of%20Oromia!5e0!3m2!1sen!2set!4v1719001064856!5m2!1sen!2set"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Supreme Court of Oromia"
        className="w-full h-96 sm:h-[450px]"
      ></iframe>
    </div>
  );
}

export default Location;
