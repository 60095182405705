import React from "react";
import HammerBanner from "../../assets/images/tradeb.jpg";
import JudicialServicesImg from "../../assets/images/1.jpg"; // Example image paths
import CourtProceduresImg from "../../assets/images/CustomerRegistration.jpg";
import CourtProceduresImg2 from "../../assets/images/customerRegistration2.jpg";
import LegalAssistanceImg from "../../assets/images/TradeControll.jpg";
import OnlineServicesImg from "../../assets/images/TradeInspection.webp";
import SupportServicesImg from "../../assets/images/CustomerRight.jpg";
import ContactInfoImg from "../../assets/images/6.jpg";
import DownloadsResourcesImg from "../../assets/images/services.jpg";
import FeedbackSupportImg from "../../assets/images/Feedback.png";
import NoticesAlertsImg from "../../assets/images/Info2.png";
import JudicialServicesDetailImg from "../../assets/images/Trade Expanstion.jpg"; // Example detail image path
import { useTranslation } from "react-i18next";
function ServicesPage() {
  const { t, i18n } = useTranslation();
  const sections = [
    {
      title: t('Business Development and Market Development Directorate'),
      description: (
        <>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
            <li>{t('Accreditation by organizing sector associations and business forums')}</li>
            <li>{t('Creating market linkages')}</li>
            <li>{t('Basic goods connection and distribution service')}</li>
          </ul>
        </>
      ),
      img: JudicialServicesDetailImg,
    },
    {
      title: t('Directorate of Business Registration and Licensing Services'),
      description: (
        <>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
            <li>{t('onsulting services for the establishment and preparation of articles of association of joint stock associations')}</li>
            <li>{t('To support and monitor complaints submitted against joint stock associations to be resolved according to the law')}</li>
            <li>
            {t('Resolve disputes and conflicts between board members and management members of stock associations.')}
            </li>
            <li>
            {t('By attending the general assembly for the annual amendments to the bylaws of the stock associations and the election of the board leadership')}
            </li>

            <li> {t('Follow up and support that it is done according to the law')}</li>
            <li>
            {t('Conduct an audit on the joint stock company at the request of the members who own 1/10th of the shares, according to the court order or the general meeting.')}
            </li>
            <li>{t('Individual and business association business registration service')}</li>
            <li>{t('Change of business registration of individual and business association')}</li>

            <li>{t('Business registration service on behalf of individual and business association')}</li>
            <li>{t('Individual trader business registration cancellation service')}</li>
          </ul>
        </>
      ),
      img: CourtProceduresImg,
    },

    {
      title: t('Directorate of Commercial Customers Registration and Licensing Services (Continued)'),
      description: (
        <>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
            <li>{t('Trade association trade registration cancellation service')}</li>
            <li>{t('New business licensing service')}</li>

            <li>{t('New business licensing service')}</li>
            <li>{t('Business license renewal service')}</li>
            <li>{t('Provision of substitute business licensing services')}</li>
            <li>{t('Business license return service')}</li>

            <li>{t('New business name registration service')}</li>
            <li>{t('Replacement business name registration service')}</li>
            {/* <li>ምትክ የንግድ ስም ምዝገባ አገልግሎት</li> */}
            <li>{t('Cancellation of business name certificate')}</li>
          </ul>
        </>
      ),
      img: CourtProceduresImg2,
    },
    {
      title: t('Trade Inspection and Regulatory Directorate'),
      description: (
        <>
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
            <li>
            {t('Monitor and verify the accuracy of measuring and measuring devices')}
            </li>
            {/* <li>
              Eligibility Criteria: Criteria for qualifying for legal aid
              services.
            </li>
            <li>Application Process: How to apply for legal assistance.</li>
            <li>
              Service Availability: Information on locations and hours of legal
              aid offices.
            </li> */}
          </ul>
        </>
      ),
      img: LegalAssistanceImg,
    },
    {
      title: t('Directorate of Informal Business'),
      description: (
        <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
          <li>{t('Directorate of Informal Business')}</li>
          {/* <li>e-Filing</li>
          <li>Payment of Fees</li>
          <li>Appointment Scheduling</li> */}
        </ul>
      ),
      img: OnlineServicesImg,
    },
    {
      title: t('Directorate of Consumer Protection'),
      description: (
        <>
          {/* <p className="text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
            We provide essential support services to ensure all individuals have
            equitable access to justice:
          </p> */}
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
            <li>
            {t('Receiving and responding to suggestions and complaints from the consumer community')}
            </li>
            {/* <li>
              Accessibility Services: Accommodations for individuals with
              disabilities to access court facilities and services.
            </li>
            <li>
              Victim Support: Counseling, advocacy, and resources for victims of
              crime to navigate the legal process.
            </li> */}
          </ul>
        </>
      ),
      img: SupportServicesImg,
    },
    {
      title: t('List of services provided by the Directorate of Development and Management of Shopping Centers'),
      description: (
        <>
          {/* <p className="text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
            Access a wide range of forms, documents, and legal publications to
            support your legal needs:
          </p> */}
          <ul className="list-disc list-inside text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
            <li>
            {t('Ensuring that traders and farmers who come to the livestock market center for trading have permission to enter the market center.')}
            </li>
            <li>
           {t('Providing lodging services without entering the livestock market center for trading')}
            </li>
            {/* <li>
              Publications: Articles, guides, and legal resources for further
              reading.
            </li> */}
          </ul>
        </>
      ),
      img: DownloadsResourcesImg,
    },
   
    
  ];

  return (
    <div className="services-page bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
      <div className="relative shadow-2xl mb-8">
        <img
          src={HammerBanner}
          className="w-full h-40 object-cover"
          alt="Banner"
        />
        <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white text-4xl md:text-6xl font-bold">
          {t("services")}
        </h1>
      </div>

      {/* Introduction Section */}
      <section className="introduction bg-white dark:bg-gray-800 p-3 md:p-12 lg:p-5">
        <div data-aos="zoom-in" duration="1000">
          <p className="text-gray-700 dark:text-gray-300 leading-relaxed text-lg md:text-4xl text-center font-bold">
           {t('List of services we provide')}
          </p>
        </div>
      </section>

      {/* Service Sections */}
      {sections.map((section, index) => (
        <section
          key={index}
          className={`flex flex-col md:flex-row items-center ${
            index % 2 === 0
              ? "bg-gray-100 dark:bg-gray-900"
              : "bg-white dark:bg-gray-800"
          } p-6 md:p-12 lg:p-20`}
        >
          <div
            data-aos="fade-right"
            data-aos-duration="2000"
            className="w-full md:w-1/2 mb-6 md:mb-0"
          >
            <img
              src={section.img}
              className="w-full h-[50vh] rounded-lg shadow-lg object-cover"
              alt={`${section.title} Image`}
            />
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="3000"
            className="w-full md:w-1/2 md:pl-12"
          >
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-gray-100 mb-4">
              {section.title}
            </h2>
            <div className="text-gray-700 dark:text-gray-300 leading-relaxed text-lg">
              {section.description}
            </div>
          </div>
        </section>
      ))}
    </div>
  );
}

export default ServicesPage;
