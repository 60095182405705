import React, { useState, useEffect } from "react";
import axios from "axios";
import Director from "../assets/images/Carousel/Shemsiya Negash.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from 'react-i18next';

const DirectorMessage = () => {
  const { t, i18n } = useTranslation();
  const [latestMessage, setLatestMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out",
      once: false,
      mirror: true,
      duration:2000,
    });
  }, []);

  useEffect(() => {
    const fetchLatestMessage = async () => {
      try {
        const response = await axios.get(
          "http://localhost:3000/api/director/messages"
        );
        // Assuming messages are sorted by createdAt descending in backend
        if (response.data.length > 0) {
          setLatestMessage(response.data[0]); // Get the first message (latest one)
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching director messages:", error);
      }
    };

    fetchLatestMessage();
  }, []);

  if (loading) {
    return <div>
    <div className="bg-gray-100 dark:bg-gray-800 py-12">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-center text-4xl md:text-4xl font-bold text-blue-800 mb-8">
          {t('Message from The President')}
        </h1>
        <div className="flex flex-col lg:flex-row gap-8 items-center">
          <div className="lg:w-1/2">
            <img
              src={Director}
              alt="Director Message Image"
              className="rounded-lg shadow-md w-full h-[60vh]"
            />
          </div>
          <div className="lg:w-1/2">
            <div className="p-4">
              <h2 className="text-2xl md:text-3xl font-bold mb-4">
              Dear Colleagues and Esteemed Members of the Judiciary,
              </h2>
              <p className="text-lg text-gray-700 mb-4">
                It is with great privilege and honor that I address you
                today as the Director of the Oromia Supreme Court. Our
                court system plays a crucial role in upholding justice and
                ensuring the rule of law across the region. As we continue
                our journey, I encourage each of you to uphold the highest
                standards of integrity, fairness, and professionalism in
                every case we handle. Together, we can strive towards a
                judiciary that is trusted by all citizens and respected
                for its commitment to justice. Let us work diligently to
                uphold the constitution and protect the rights of all
                individuals who seek justice through our courts. Thank you
                for your dedication and hard work. Sincerely,
              </p>
              <p className="text-xl font-bold text-blue-800">
              Mr. Gazali Abba Simal
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  }

  if (!latestMessage) {
    return (
      <>
        <div>
          <div className="bg-gray-100 dark:bg-gray-800 py-12">
            <div className="max-w-6xl mx-auto">
              <h1 className="text-center text-4xl md:text-5xl font-bold text-blue-800 mb-8">
              {t('Message from The President')}
              </h1>
              <div className="flex flex-col lg:flex-row gap-8 items-center">
                <div className="lg:w-1/2">
                  <img
                    src={Director}
                    alt="Director Message Image"
                    className="rounded-lg shadow-md w-full h-[60vh]"
                  />
                </div>
                <div className="lg:w-1/2">
                  <div className="p-4">
                    <h2 className="text-2xl md:text-3xl font-bold mb-4">
                    Dear Colleagues and Esteemed Members of the Judiciary,
                    </h2>
                    <p className="text-lg text-gray-700 mb-4">
                      It is with great privilege and honor that I address you
                      today as the Director of the Oromia Supreme Court. Our
                      court system plays a crucial role in upholding justice and
                      ensuring the rule of law across the region. As we continue
                      our journey, I encourage each of you to uphold the highest
                      standards of integrity, fairness, and professionalism in
                      every case we handle. Together, we can strive towards a
                      judiciary that is trusted by all citizens and respected
                      for its commitment to justice. Let us work diligently to
                      uphold the constitution and protect the rights of all
                      individuals who seek justice through our courts. Thank you
                      for your dedication and hard work. Sincerely,
                    </p>
                    <p className="text-xl font-bold text-blue-800">
                    Mr. Gazali Abba Simal
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="bg-gray-100 dark:bg-gray-800 py-12">
      <div className="max-w-6xl mx-auto">
        <div
        data-aos="zoom-in"
        data-aos-duration="2000"
        >
        <h1 className="text-center text-4xl md:text-5xl font-bold text-blue-800 mb-8">
        {t('Message from The President')}
        </h1>
        </div>
       
        <div className="flex flex-col lg:flex-row gap-8 items-center">
          <div
          data-aos="fade-right"

          className="lg:w-1/2">
            {latestMessage.image && (
              <img
                src={`http://localhost:3000/uploads/DirectorMessage/${latestMessage.image}`}
                alt="Director Message Image"
                className="rounded-lg shadow-md w-full h-[60vh]"
              />
            )}
          </div>
          <div 
          data-aos="fade-left"
          className="lg:w-1/2">
            <div className="p-4">
              <h2 className="text-2xl md:text-3xl font-bold mb-4">
                {latestMessage.title}
              </h2>
              <p className="text-lg text-gray-700 mb-4">
                {latestMessage.description}
              </p>
              <p className="text-xl font-bold text-blue-800">
                {latestMessage.name}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectorMessage;
